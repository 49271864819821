package com.geekorum.eternity.funeralportal.components

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext

val THEME_GOLD_COLOR = Color.rgb(0xd2b356)

@InitSilk
fun overrideSilkTheme(context: InitSilkContext) {
    context.theme.palettes.light.border = THEME_GOLD_COLOR
    context.theme.palettes.dark.border = THEME_GOLD_COLOR
}