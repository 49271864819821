package com.geekorum.eternity.funeralportal.components.widgets

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.ButtonStyle
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.style.common.ariaDisabled
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

val OutlinedButtonVariant by ButtonStyle.addVariant {
    val palette = colorMode.toSilkPalette()
    val buttonColors = palette.button

    base {
        Modifier
            .color(palette.color)
            .backgroundColor(palette.background)
            .fontWeight(FontWeight.Bold)
            .borderWidth(3.px)
            .borderRadius(0.px)
            .borderStyle(LineStyle.Solid)
            .borderColor(Color.rgb(0))
    }

    (hover + not(ariaDisabled)) {
        Modifier.borderColor(palette.border)
            .backgroundColor(palette.background)
            .color(palette.border)
            .borderStyle(LineStyle.Solid)
    }

    (active + not(ariaDisabled)) {
        Modifier.backgroundColor(palette.background)
    }
}