package com.geekorum.eternity.funeralportal.pages

import androidx.compose.runtime.Composable
import com.geekorum.eternity.funeralportal.components.PageTitle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import components.layouts.MainLayout
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

// Dynamic routing are not supported by static export needed for firebase hosting
@Page("order-confirmation")
@Composable
fun OrderReceived() {
    val pageCtx = rememberPageContext()
    val orderId = pageCtx.route.params["orderId"]
    MainLayout {
        if (orderId?.isNotBlank() == true) {
            OrderReceived(orderId)
        } else {
            OrderNotFound()
        }
    }
}

@Composable
fun OrderReceived(orderId: String) {
    PageTitle("Merci de votre commande")

    P {
        Text("Votre commande de mémorial ")
        SpanText("#$orderId", Modifier.fontWeight(FontWeight.Bold))
        Text(" a bien été enregistrée.")
    }
}

@Composable
fun OrderNotFound(orderId: String = "") {
    Text("order $orderId not found")
}