package com.geekorum.eternity.funeralportal.components.sections

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticMouseEvent
import com.geekorum.eternity.funeralportal.components.THEME_GOLD_COLOR
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.silk.components.icons.mdi.MdiClose
import com.varabyte.kobweb.silk.components.icons.mdi.MdiMenu
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIf
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.DivText
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.Header
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Nav
import org.jetbrains.compose.web.dom.Ul

val NavHeaderStyle by ComponentStyle {
    base {
        Modifier.color(Color.rgb(0x010101))
            .fontSize(13.px)
            .fontFamily("Open Sans", "sans-serif", "Arial", "Helvetica")
    }
    Breakpoint.LG {
        Modifier.maxWidth(1250.px)
    }
}

@Composable
fun NavHeader(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = null
) {
    val finalModifier = NavHeaderStyle.toModifier(variant).then(modifier)
    Header(attrs = finalModifier.fillMaxWidth().toAttrs()) {
        Nav {
            Surface {
                var mobileMenuIsOpen by remember { mutableStateOf(false) }
                Column(modifier = Modifier.fillMaxWidth()) {
                    Row(modifier = Modifier.fillMaxWidth(), verticalAlignment = Alignment.Bottom) {
                        Logo(Modifier.padding(leftRight = 20.px))
                        Spacer()

/*
                        InlineMainNavigation()

                        DrawerButton(
                            isOpen = mobileMenuIsOpen,
                            onClick = {
                                mobileMenuIsOpen = !mobileMenuIsOpen
                            })
 */
                    }
/*
                    if (mobileMenuIsOpen) {
                        MobileMainNavigation()
                    }
*/
                }
            }
        }
    }
}

val DrawerButtonStyle by ComponentStyle {
    base {
        Modifier
            .size(40.px)
    }
}

@Composable
private fun DrawerButton(
    isOpen: Boolean,
    onClick: (SyntheticMouseEvent) -> Unit
) {
    Box(DrawerButtonStyle.toModifier()
        .displayUntil(Breakpoint.MD)
            .onClick(onClick),
    ) {
        if (isOpen) {
            MdiClose()
        } else {
            MdiMenu()
        }
    }
}

@Composable
private fun Logo(modifier: Modifier = Modifier) {
    DivText(
        "Eternity", modifier.fontSize(28.px)
            .fontWeight(FontWeight.Black)
            .lineHeight(60.px)
    )
}

val InlineMainNavigation by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("text-transform", "uppercase")
        }
    }
}



@Composable
private fun InlineMainNavigation() {
    Row(InlineMainNavigation.toModifier()
        .displayIf(Breakpoint.MD)) {
        NavItem("A propos")
        NavItem("Galerie")
        NavItem("Témoignages")
    }
}

val MobileMainNavigation by ComponentStyle {
    base {
        Modifier.styleModifier {
            property("text-transform", "uppercase")
        }
    }
}

@Composable
private fun MobileMainNavigation() {
    AppGlobalsLocal.current
    Ul(MobileMainNavigation.toModifier().position(Position.Absolute).toAttrs()) {
        Li(attrs = {
            style {
                property("list-style", "none")
            }
        }) {
            NavItem("A propos")
        }
        Li( attrs =  Modifier.fillMaxWidth().listStyle("none").toAttrs()) {
            NavItem("Galerie")
        }
        NavItem("Témoignages")

    }
}



val NavItemStyle by ComponentStyle {
    base {
        Modifier
            .padding(leftRight = 10.px)
            .fontSize(13.px)
            .textDecorationLine(TextDecorationLine.None)
    }
    Breakpoint.ZERO {
        Modifier.borderTop(width = 1.px, style = LineStyle.Solid, Color.rgb(0xe1e1e1))
            .height(auto)
            .padding(topBottom = 12.px)
    }
    Breakpoint.MD {
        Modifier.height(71.px)
            .lineHeight(71.px)
            .padding(leftRight = 10.px)
    }
}

val NavHeaderLinkVariant by LinkStyle.addVariant {
    hover {
        Modifier.color(THEME_GOLD_COLOR)
    }
}

@Composable
private fun NavItem(
    text: String,
    path: String = "#$text",
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = null
) {
    val finalModifier = NavItemStyle.toModifier(variant).then(modifier)
    Link(
        path, modifier = finalModifier,
        variant = UncoloredLinkVariant.then(UndecoratedLinkVariant)
            .then(NavHeaderLinkVariant)
    ) {
        SpanText(text)
    }
}