package com.geekorum.eternity.funeralportal.pages

import androidx.compose.runtime.*
import com.benasher44.uuid.uuid4
import com.geekorum.eternity.funeralportal.LocalFirebaseApp
import firebase.firestore.*
import firebase.storage.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.w3c.files.File
import kotlin.js.json
import kotlin.reflect.KFunction1

class HomepageViewModel(
    private val storage: FirebaseStorage,
    private val firestore: Firestore,
    private val viewModelScope: CoroutineScope
) {
    val id = uuid4()
    var lastname by mutableStateOf("")
    var firstname by mutableStateOf("")
    var dateOfBirth by mutableStateOf("")
    var dateOfDeath by mutableStateOf("")
    var introduction by mutableStateOf("")
    var earlyLife by mutableStateOf("")
    var career by mutableStateOf("")
    var personalLife by mutableStateOf("")
    var legacy by mutableStateOf("")
    var conclusion by mutableStateOf("")

    var introductionUploadFileTask by mutableStateOf<UploadFileTask?>(null)
        private set
    var earlyLifeUploadFileTask by mutableStateOf<UploadFileTask?>(null)
        private set
    var careerUploadFileTask by mutableStateOf<UploadFileTask?>(null)
        private set
    var personalLifeUploadFileTask by mutableStateOf<UploadFileTask?>(null)
        private set
    var legacyUploadFileTask by mutableStateOf<UploadFileTask?>(null)
        private set
    var additionalUploadFileTasks by mutableStateOf<List<UploadFileTask>>(emptyList())
        private set

    fun uploadIntroPhoto(file: File) {
        uploadPhoto(file, "intro_photo.${file.name.extension}", ::introductionUploadFileTask::set)
    }

    fun uploadEarlyLifePhoto(file: File) {
        uploadPhoto(file, "early_life_photo.${file.name.extension}", ::earlyLifeUploadFileTask::set)
    }

    fun uploadCareerPhoto(file: File) {
        uploadPhoto(file, "career_photo.${file.name.extension}", ::careerUploadFileTask::set)
    }

    fun uploadPersonalLifePhoto(file: File) {
        uploadPhoto(file, "personal_life_photo.${file.name.extension}", ::personalLifeUploadFileTask::set)
    }

    fun uploadLegacyPhoto(file: File) {
        uploadPhoto(file, "legacy_photo.${file.name.extension}", ::legacyUploadFileTask::set)
    }

    private val String.extension
        get() = takeLastWhile { it != '.' }

    private fun uploadPhoto(
        file: File,
        destinationName: String = file.name,
        taskSetter: KFunction1<UploadFileTask?, Unit>) {
        val task = uploadFile(file, destinationName = destinationName, onErrorCancelled = {
            taskSetter(null)
        })
        taskSetter( UploadFileTask(file, task))
    }

    fun uploadAdditionalPhotos(files: List<File>) {
        for (file in files) {
            val task = uploadFile(file,
                onErrorCancelled = { cancelledTask ->
                    val toRemove = additionalUploadFileTasks.find { it.task == cancelledTask }
                    if (toRemove != null) {
                        additionalUploadFileTasks = additionalUploadFileTasks - toRemove
                    }
                }
            )
            additionalUploadFileTasks += UploadFileTask(file, task)
        }
    }

    fun removeAdditionalPhoto(uploadFileTask: UploadFileTask) {
        uploadFileTask.task.cancel()
        additionalUploadFileTasks -= uploadFileTask
    }

    private fun uploadFile(
        file: File,
        destinationName: String = file.name,
        onErrorCancelled:(UploadTask) -> Unit) : UploadTask {
        val ref = ref(storage, "funeral-portal/pending-orders/$id/medias/$destinationName")
        val task = uploadBytesResumable(ref, file)
        task.on("state_change",
            next = {
                if (it.state in listOf("cancelled", "error")) {
                    onErrorCancelled(it.task)
                }
        })
        return task
    }

    fun checkout() = viewModelScope.launch{
        savePendingMemorialOrder()
    }

    private suspend fun savePendingMemorialOrder() {
        val docRef: DocumentReference<Any> = doc(firestore, "funeral-portal-pending-orders/$id")
        val content = json(
            "createdAt" to serverTimestamp(),
            "firstname" to firstname,
            "lastname" to lastname,
            "dateOfBirth" to dateOfBirth,
            "dateOfDeath" to dateOfDeath,
            "introduction" to introduction,
            "earlyLife" to earlyLife,
            "career" to career,
            "personalLife" to personalLife,
            "legacy" to legacy,
            "conclusion" to conclusion,
            "introduction_media" to introductionUploadFileTask?.task?.snapshot?.ref?.fullPath,
            "earlyLife_media" to earlyLifeUploadFileTask?.task?.snapshot?.ref?.fullPath,
            "career_media" to careerUploadFileTask?.task?.snapshot?.ref?.fullPath,
            "personalLife_media" to personalLifeUploadFileTask?.task?.snapshot?.ref?.fullPath,
            "legacy_media" to legacyUploadFileTask?.task?.snapshot?.ref?.fullPath,
            "additional_medias" to additionalUploadFileTasks.map {
                it.task.snapshot.ref.fullPath
            }.toTypedArray()
        )
        setDoc(docRef, content).await()
    }
}

@Composable
fun rememberHomepageViewModel(): HomepageViewModel {
    val firebaseApp = LocalFirebaseApp.current
    val storage = getStorage(firebaseApp)
    val firestore = getFirestore(firebaseApp)
    val coroutineScope = rememberCoroutineScope()
    return remember {
        HomepageViewModel(storage, firestore, coroutineScope)
    }
}

data class UploadFileTask(
    val file: File,
    val task: UploadTask
)

@Composable
fun UploadFileTask.collectTaskSnapshotAsState() = produceState<UploadTaskSnapshot?>(task.snapshot, this) {
    val unsubscribe = task.on("state_change",
        next = { value = it },
        error = {
            console.error("something went wrong", it)
            value = task.snapshot
        },
        complete = {
            value = task.snapshot
        })
    awaitDispose { unsubscribe() }
}
