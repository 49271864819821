package components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextArea

@Composable
fun SingleLineTextField(
    label: String,
    id: String,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        Label(forId = id) {
            Text(label)
        }
        Input(type = InputType.Text, attrs = {
            id(id)
        })
    }
}

@Composable
fun SingleLineTextField(
    label: String,
    id: String,
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    required: Boolean = false
) {
    Column(modifier) {
        Label(forId = id) {
            Text(label)
        }
        InputField(id, type = InputType.Text, value, onValueChange, required = required)
    }
}



val MultiLineTextFieldStyle by ComponentStyle {
    base {
        Modifier
    }
}

@Composable
fun MultiLineTextField(
    label: String,
    id: String,
    modifier: Modifier = Modifier
) {
    Column(MultiLineTextFieldStyle.toModifier().then(modifier)) {
        Label(forId = id, attrs = Modifier.padding(bottom = 5.px).toAttrs()) {
            SpanText(label, Modifier.fontSize(15.px))
        }
        TextArea(attrs = {
            id(id)
            rows(8)
            cols(50)
        })
    }
}

@Composable
fun MultiLineTextField(
    label: String,
    id: String,
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    required: Boolean = false
) {
    Column(MultiLineTextFieldStyle.toModifier().then(modifier)) {
        Label(forId = id, attrs = Modifier.padding(bottom = 5.px).toAttrs()) {
            SpanText(label, Modifier.fontSize(15.px))
        }
        TextArea(value = value,
            attrs = {
                id(id)
                onInput { event -> onValueChange(event.value) }
                rows(8)
                cols(50)
                if (required) {
                    required()
                }
            })
    }
}



@Composable
fun DateField(
    label: String,
    id: String,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        Label(forId = id) {
            Text(label)
        }
        Input(InputType.Date, attrs = {
            id(id)
        })
    }
}

@Composable
fun DateField(
    label: String,
    id: String,
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier,
    required: Boolean = false
) {
    Column(modifier) {
        Label(forId = id) {
            Text(label)
        }
        InputField(id = id, type = InputType.Date, value, onValueChange, required = required)
    }
}


@Composable
private fun <T> InputField(
    id: String, type: InputType<T>,
    value: T, onValueChange: (T) -> Unit,
    required: Boolean = false) {
    Input(type = type,
        attrs = {
            id(id)
            value(value.toString())
            onInput { event ->
                onValueChange(event.value)
            }
            if (required) {
                required()
            }
        })
}
