package components.widgets

import androidx.compose.runtime.Composable
import com.geekorum.eternity.funeralportal.components.THEME_GOLD_COLOR
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.hidden
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.thenUnless
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiPhotoCamera
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.accept
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.w3c.dom.asList
import org.w3c.files.File


@Composable
fun PhotoFilePickerButton(
    id: String,
    modifier: Modifier = Modifier,
    multiple: Boolean = false,
    onFileSelected: (List<File>) -> Unit
) {
    FilePicker(
        id = id,
        modifier = modifier,
        accept = "image/*",
        multiple = multiple,
        onFilesSelected = onFileSelected,
        label = {
            Box(
                modifier = Modifier.size(80.px)
                    .border(2.px, color = THEME_GOLD_COLOR, style = LineStyle.Solid),
                contentAlignment = Alignment.Center
            ) {
                MdiPhotoCamera()
            }
        }
    )
}


private val DefaultFilePickerLabel: @Composable () -> Unit = {}

@Composable
fun FilePicker(
    id: String,
    onFilesSelected: (List<File>) -> Unit,
    modifier: Modifier = Modifier,
    label: @Composable () -> Unit = DefaultFilePickerLabel,
    accept: String? = null,
    multiple: Boolean = false,
) {
    Div(modifier.toAttrs()) {
        Label(forId = id) { label() }
        Input(type = InputType.File,
            attrs = Modifier
                .thenUnless(label == DefaultFilePickerLabel, Modifier.hidden())
                .toAttrs {
                    id(id)
                    accept?.let { accept("image/*") }
                    if (multiple) multiple()
                    onChange { syntheticChangeEvent ->
                        val files = syntheticChangeEvent.target.files?.asList() ?: emptyList()
                        onFilesSelected(files)
                    }
                }
        )
    }
}