package components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import com.geekorum.eternity.funeralportal.components.sections.NavHeader
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent


val LocalBreakpoint = compositionLocalOf {
    Breakpoint.ZERO
}

val MainLayoutStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth()
    }
    Breakpoint.MD {
        Modifier.fillMaxWidth(70.percent)
    }
}

@Composable
fun MainLayout(
    content: @Composable () -> Unit,
) {
    Surface(Modifier.fillMaxSize()) {
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
            Column(MainLayoutStyle.toModifier()) {
                NavHeader(Modifier.align(Alignment.CenterHorizontally))
                content()
            }
        }
    }
}