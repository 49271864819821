package com.geekorum.eternity.funeralportal.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.mdi.MdiPerson
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.after
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection.Companion.Row
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2

val PageTitleStyle by ComponentStyle {
    base {
        Modifier
        .fontFamily("Open Sans", "sans-serif", "Arial", "Helvetica")
    }
    after {
        Modifier.backgroundColor(THEME_GOLD_COLOR)
            .width(30.px)
            .height(5.px)
            .margin(top = 30.px)
            .content("")
            .display(DisplayStyle.Block)
    }
}

@Composable
fun PageTitle(title: String, modifier: Modifier = Modifier) {
    H1(PageTitleStyle.toModifier().then(modifier).toAttrs()) {
        SpanText(title)
    }
}

val SectionTitleStyle by ComponentStyle {
    base {
        Modifier
            .fontFamily("Open Sans", "sans-serif", "Arial", "Helvetica")
    }
    after {
        Modifier.backgroundColor(THEME_GOLD_COLOR)
            .width(30.px)
            .height(5.px)
            .margin(top = 15.px)
            .content("")
            .display(DisplayStyle.Block)
    }
}


@Composable
fun SectionTitle(
    title: String,
    icon: (@Composable () -> Unit)? = null,
    modifier: Modifier = Modifier
) {
    H2(SectionTitleStyle.toModifier().then(modifier).toAttrs()) {
        Row(verticalAlignment = Alignment.Bottom) {
            if (icon != null) {
                Box(Modifier.color(THEME_GOLD_COLOR)
                    .padding(right = 10.px)
                ) {
                    icon.invoke()
                }
            }
            SpanText(title)
        }
    }
}