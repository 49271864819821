import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.geekorum.eternity.funeralportal.pages.Homepage() }
        ctx.router.register("/order-confirmation") {
                com.geekorum.eternity.funeralportal.pages.OrderReceived() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.sections.NavHeaderStyle)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.sections.DrawerButtonStyle)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.sections.InlineMainNavigation)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.sections.MobileMainNavigation)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.sections.NavItemStyle)
        ctx.theme.registerComponentStyle(components.layouts.MainLayoutStyle)
        ctx.theme.registerComponentStyle(components.widgets.MultiLineTextFieldStyle)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.PageTitleStyle)
        ctx.theme.registerComponentStyle(com.geekorum.eternity.funeralportal.components.SectionTitleStyle)
        ctx.theme.registerComponentVariants(com.geekorum.eternity.funeralportal.components.sections.NavHeaderLinkVariant)
        ctx.theme.registerComponentVariants(com.geekorum.eternity.funeralportal.components.widgets.OutlinedButtonVariant)
        com.geekorum.eternity.funeralportal.components.overrideSilkTheme(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin))

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "funeral-portal")
        ) { com.geekorum.eternity.funeralportal.FuneralPortalApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}
