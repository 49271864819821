package com.geekorum.eternity.funeralportal.pages

import androidx.compose.runtime.*
import com.geekorum.eternity.funeralportal.components.PageTitle
import com.geekorum.eternity.funeralportal.components.SectionTitle
import com.geekorum.eternity.funeralportal.components.widgets.OutlinedButtonVariant
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.mdi.MdiAutoStories
import com.varabyte.kobweb.silk.components.icons.mdi.MdiPerson
import components.layouts.MainLayout
import components.widgets.DateField
import components.widgets.MultiLineTextField
import components.widgets.PhotoFilePickerButton
import components.widgets.SingleLineTextField
import firebase.storage.*
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.FormMethod
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.method
import org.jetbrains.compose.web.attributes.name
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLFormElement
import org.w3c.dom.url.URL
import org.w3c.files.File

//TODO
//private const val CREATE_CHECKOUT_SESSION_URL = "http://127.0.0.1:5001/eternity-797ce/us-central1/createCheckoutSessionForFuneralPortal"

private const val CREATE_CHECKOUT_SESSION_URL = "https://createcheckoutsessionforfuneralportal-q7uxb5pbeq-uc.a.run.app"

@Page
@Composable
fun Homepage() {
    val pageContext = rememberPageContext()
    MainLayout {
        Column(
            Modifier.fillMaxWidth()
                .padding(leftRight = 20.px)
        ) {
            PageTitle(title = "Formulaire de création de memorial")
            Form(
                action = CREATE_CHECKOUT_SESSION_URL,
                attrs = {
                    method(FormMethod.Post)
                }
            ) {
                var formElement by remember { mutableStateOf<HTMLFormElement?>(null) }
                DisposableEffect(Unit) {
                    formElement = scopeElement
                    onDispose {
                        formElement = null
                    }
                }
                val viewModel = rememberHomepageViewModel()
                HiddenInput(attrs = {
                    name("memorialId")
                    value(viewModel.id.toString())
                })

                Input(type = InputType.Hidden,attrs = {
                    name("order_type")
                    value("funeral_order")
                })

                Input(type = InputType.Hidden,attrs = {
                    name("website")
                    value(window.location.href)
                })

                Input(type = InputType.Hidden,attrs = {
                    name("promotion_code")
                    value(pageContext.route.params["promotion_code"] ?: "")
                })

                IdentitySection(viewModel, Modifier.padding(bottom = 80.px))
                StorySection(viewModel, Modifier.padding(bottom = 80.px))
                PhotosSection(viewModel, Modifier.padding(bottom = 80.px))

                val coroutineScope = rememberCoroutineScope()
                Button(
                    modifier = Modifier.margin(bottom = 80.px),
                    variant = OutlinedButtonVariant,
                    onClick = {
                        if (formElement?.reportValidity() == true) {
                            coroutineScope.launch {
                                viewModel.checkout().join()
                                formElement?.submit()
                            }
                        }
                    }) {
                    Text("Valider et payer")
                }
            }
        }
    }
}




@Composable
private fun IdentitySection(viewModel: HomepageViewModel, modifier: Modifier = Modifier) {
    Column(modifier) {
        SectionTitle("Identité", icon = { MdiPerson() })
        SingleLineTextField("Nom", id = "lastname",
            required = true,
            value = viewModel.lastname,
            onValueChange = {
                viewModel.lastname = it
            },
            modifier = Modifier.padding(bottom = 15.px)
        )
        SingleLineTextField(
            "Prénom", id = "firstname",
            required = true,
            value = viewModel.firstname,
            onValueChange = { viewModel.firstname = it },
            modifier = Modifier.padding(bottom = 30.px))
        DateField("Date de naissance", id = "date_of_birth",
            required = true,
            value = viewModel.dateOfBirth,
            onValueChange = { viewModel.dateOfBirth = it },
            modifier = Modifier.padding(bottom = 15.px))
        DateField("Date de décès", id = "date_of_death",
            required = true,
            value = viewModel.dateOfDeath,
            onValueChange = { viewModel.dateOfDeath = it },
        )
    }
}


@Composable
private fun StorySection(
    viewModel: HomepageViewModel,
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        SectionTitle("Histoire", icon = { MdiAutoStories() })
        Row(verticalAlignment = Alignment.Bottom, modifier = Modifier.padding(bottom = 30.px)) {
            MultiLineTextField("Introduction", id = "introduction",
                value = viewModel.introduction,
                onValueChange = { viewModel.introduction = it },
                required = true
            )
            SinglePhotoUploadFilePicker(id = "introduction_photo_picker",
                viewModel.introductionUploadFileTask, onFileSelected = {
                    viewModel.uploadIntroPhoto(it)
                },
                modifier = Modifier.margin(left = 40.px))
        }

        Row(verticalAlignment = Alignment.Bottom, modifier = Modifier.padding(bottom = 30.px)) {
            MultiLineTextField("Jeunesse", id = "early_life",
                value = viewModel.earlyLife,
                onValueChange = { viewModel.earlyLife = it },
                required = true
            )
            SinglePhotoUploadFilePicker(id = "early_life_photo_picker",
                viewModel.earlyLifeUploadFileTask, onFileSelected = {
                    viewModel.uploadEarlyLifePhoto(it)
                },
                modifier = Modifier.margin(left = 40.px))
        }

        Row(verticalAlignment = Alignment.Bottom, modifier = Modifier.padding(bottom = 30.px)) {
            MultiLineTextField("Vie professionnelle", id = "career",
                value = viewModel.career,
                onValueChange = { viewModel.career = it },
                required = true
            )
            SinglePhotoUploadFilePicker(id = "career_photo_picker",
                viewModel.careerUploadFileTask, onFileSelected = {
                    viewModel.uploadCareerPhoto(it)
                },
                modifier = Modifier.margin(left = 40.px))
        }


        Row(verticalAlignment = Alignment.Bottom, modifier = Modifier.padding(bottom = 30.px)) {
            MultiLineTextField("Vie personnelle", id = "personal_life",
                value = viewModel.personalLife,
                onValueChange = { viewModel.personalLife = it },
                required = true
            )
            SinglePhotoUploadFilePicker(id = "personal_life_photo_picker",
                viewModel.personalLifeUploadFileTask, onFileSelected = {
                    viewModel.uploadPersonalLifePhoto(it)
                },
                modifier = Modifier.margin(left = 40.px))
        }

        Row(verticalAlignment = Alignment.Bottom, modifier = Modifier.padding(bottom = 30.px)) {
            MultiLineTextField("Héritage", id = "legacy",
                value = viewModel.legacy,
                onValueChange = { viewModel.legacy = it },
                required = true
            )
            SinglePhotoUploadFilePicker(id = "legacy_photo_picker",
                viewModel.legacyUploadFileTask, onFileSelected = {
                    viewModel.uploadLegacyPhoto(it)
                },
                modifier = Modifier.margin(left = 40.px))
        }

        MultiLineTextField("Conclusion", id = "conclusion",
            value = viewModel.conclusion,
            onValueChange = { viewModel.conclusion = it },
            required = true
        )
    }
}


@Composable
fun PhotosSection(viewModel: HomepageViewModel, modifier: Modifier = Modifier) {
    Column(modifier) {
        SectionTitle("Photos supplémentaires")

        val photoTasks = viewModel.additionalUploadFileTasks

        Box(Modifier.gridTemplateColumns("80px 80px 80px auto")) {
            photoTasks.forEach { task ->
                Image(src = URL.createObjectURL(task.file),
                    modifier = Modifier.size(80.px)
                        .gridArea("revert")
                        .onClick {
                            viewModel.removeAdditionalPhoto(task)
                        }
                )
            }

            PhotoFilePickerButton(id = "photo_section_picker",
                multiple = true,
                modifier = Modifier.gridArea("auto"),
                onFileSelected = {
                    viewModel.uploadAdditionalPhotos(it)
            })
        }
    }
}

@Composable
private fun SinglePhotoUploadFilePicker(
    id: String,
    fileUploadFileTask: UploadFileTask?,
    onFileSelected: (File) -> Unit,
    modifier: Modifier = Modifier
) {
    Box(modifier) {
        if (fileUploadFileTask == null) {
            PhotoFilePickerButton(id = id,
                onFileSelected = {
                    it.firstOrNull()?.let { file ->
                        onFileSelected(file)
                    }
                }
            )
        } else {
            val snapshot by fileUploadFileTask.collectTaskSnapshotAsState()
            PhotoUploadProgress(fileUploadFileTask.file, snapshot!!)
        }
    }
}

@Composable
fun PhotoUploadProgress(file:File, uploadTaskSnapshot: UploadTaskSnapshot, modifier: Modifier = Modifier) {
    Box(modifier = modifier) {
        Image(
            src = URL.createObjectURL(file),
            modifier = Modifier.size(80.px)
        )
        if (uploadTaskSnapshot.state in listOf("running", "paused")) {
            Progress(attrs = Modifier.align(Alignment.BottomCenter).width(75.px).toAttrs {
                attr("value", uploadTaskSnapshot.bytesTransferred.toString())
                attr("max", uploadTaskSnapshot.totalBytes.toString())
            })
        }
    }
}
