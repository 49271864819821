package com.geekorum.eternity.funeralportal

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.staticCompositionLocalOf
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import firebase.app.FirebaseApp
import firebase.app.initializeApp
import firebase.firestore.connectFirestoreEmulator
import firebase.firestore.getFirestore
import firebase.storage.connectStorageEmulator
import firebase.storage.getStorage
import kotlinx.browser.window

private val FIREBASE_CONFIG = js("process.env.FIREBASE_CONFIG")

val LocalFirebaseApp = staticCompositionLocalOf<FirebaseApp> {
    error("FirebaseApp is not initialized")
}

@App
@Composable
fun FuneralPortalApp(content: @Composable () -> Unit) {
    val firebaseApp = initializeApp(FIREBASE_CONFIG)
    if (window.location.hostname == "localhost" || window.location.hostname == "127.0.0.1" ){
        connectStorageEmulator(getStorage(firebaseApp), "localhost", 9199)
        println("firebase storage will connect to local emulator on localhost:9199")
        connectFirestoreEmulator(getFirestore(firebaseApp), "localhost", 8081)
        println("firebase firestore will connect to local emulator on localhost:8081")
    }
    CompositionLocalProvider(LocalFirebaseApp providesDefault  firebaseApp) {
        SilkApp(content)
    }
}